


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PlayBtn from '@/components/atoms/btns/Play.vue'
import screenfull from 'screenfull'

@Component({
  components: {
    PlayBtn,
  },
})
export default class VideoWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean

  playing = false

  fullscreen = false

  $refs!: {
    video: HTMLVideoElement
  }

  @Watch('intersect')
  onIntersectChange(intersect: boolean) {
    if (!intersect) {
      this.playing = false
      const { video } = this.$refs
      if (!video.paused) video.pause()
    }
  }

  @Watch('fullscreen')
  onFullscreenChange() {
    if (!this.fullscreen) {
      const { video } = this.$refs
      if (!video.paused) video.pause()
    }
  }

  onFullscreenUpdate() {
    const { isFullscreen } = screenfull as any
    this.fullscreen = isFullscreen
    if (!this.fullscreen) (screenfull as any).off('change', this.onFullscreenUpdate)
  }

  fullscreenRequest(element: HTMLElement) {
    if ('android' === this.$device.system.os && screenfull.isEnabled) {
      screenfull.on('change', this.onFullscreenUpdate)
      screenfull.request(element)
    }
  }

  playVideo() {
    const { video } = this.$refs
    this.fullscreenRequest(video)
    video.play()
  }

  onPlay() {
    this.playing = true
  }

  onPause() {
    //this.playing = false
  }

  mounted() {
    this.$refs.video.addEventListener('play', this.onPlay)
    this.$refs.video.addEventListener('pause', this.onPause)
  }

  beforeDestroy() {
    this.$refs.video.removeEventListener('pause', this.onPause)
    this.$refs.video.removeEventListener('play', this.onPlay)
  }
}
