

























import { Component } from 'vue-property-decorator'
import { planetFarmsService } from '@/services'
import ProductsWidget from '@/components/organsims/widgets/Products.vue'
import GridboxWidget from '@/components/organsims/widgets/Gridbox.vue'
import ArticleWidget from '@/components/organsims/widgets/Article.vue'
import VisualWidget from '@/components/organsims/widgets/Visual.vue'
import VideoWidget from '@/components/organsims/widgets/Video.vue'
import CoverWidget from '@/components/organsims/widgets/Cover.vue'
import Anchors from '@/components/molecules/Anchors.vue'
import Layout from '@/components/organsims/Layout.vue'
import View from '@/mixins/View'

@Component({
  components: {
    ProductsWidget,
    ArticleWidget,
    GridboxWidget,
    VisualWidget,
    VideoWidget,
    CoverWidget,
    Anchors,
    Layout,
  },
})
export default class PlanetFarms extends View {
  widgets: any = []

  anchors = {
    main: -1,
  }

  $refs!: {
    widgets: any[]
  }

  get navigation() {
    return this.widgets
      .map(({ theme: { anchor } }: any) => anchor)
      .filter((anchor: any) => anchor)
  }

  getWidgetId(anchor: any) {
    return anchor ? anchor.name : undefined
  }

  anchorTo({ main }: any) {
    const { name } = this.navigation[main]
    this.$state.needsLogo = false
    this.$fn.scrollTo(name)
  }

  scroll({ offset }: any) {
    const anchor = this.getCurrentAnchor(offset)
    const index = this.navigation.indexOf(anchor)
    this.anchors.main = index

    if (!this.$state.needsLogo && !this.$state.smoothScroll) this.$state.needsLogo = true
  }

  getCurrentAnchor(offset: any) {
    let result
    const { widgets } = this.$refs
    const { innerHeight: height } = window
    for (let i = 0; i < widgets.length; ++i) {
      const $widget = widgets[i].$el
      const y = $widget.offsetTop
      const offsetTop = y - offset.y
      if (offsetTop < height / 2) {
        const { anchor } = this.widgets[i].theme
        if (anchor) result = anchor
      }
    }
    return result
  }

  async created() {
    this.widgets = await this.fetch(planetFarmsService)
  }
}
