





















import { gsap } from 'gsap'
import { Component, Vue, Prop } from 'vue-property-decorator'
import Heading from '@/components/atoms/Heading.vue'
import Resizer from '@/observable/Resizer'

@Component({
  components: {
    Heading,
    Resizer,
  },
})
export default class VisualWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean

  fontSize = 0

  $refs!: {
    display: HTMLElement
    title: Heading
  }

  resize({ height }: any) {
    const offsetTop = 118 + height * 0.05
    const offsetBot = 69 + height * 0.05
    const offset = offsetTop + offsetBot
    const size = (height - offset) / 6
    this.fontSize = size

    const { display, title } = this.$refs
    const { clientWidth } = document.documentElement
    gsap.set(display, { width: height, height: clientWidth, lineHeight: `${clientWidth - 2}px` })
    gsap.set(title.$el, { x: -size * 0.025, padding: `0 0 0 ${offsetBot}px` })
  }
}
